import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collectionsSelector,
  getAccountCollections,
} from '../../store/feature/Collections/CollectionsSlice';
import AccountCollection from '../../components/Collections/AccountCollection';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';

const AccountCollectionsPageLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountCollections({ bodyParam: { scope_type: '1' } }));
  }, []);
  const { account_collections, isFetching } = useSelector(collectionsSelector);
  return (
    <div className="  mt-10 flex flex-col gap-10">
      {isFetching && <CustomLoading />}
      {account_collections?.map((collection) => {
        return (
          <div key={collection.id}>
            <AccountCollection account_collection={collection} styles={{}} />
          </div>
        );
      })}
    </div>
  );
};

export default AccountCollectionsPageLayout;
