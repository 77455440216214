import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import {
  AllPromoBanners,
  homeCollections,
  homeSelector,
} from '../../store/feature/homeSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import CustomListings from '../../components/home/Listing/CustomListings';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Home_page_text } from '../../constant/Translation/Home';
import Latest_blogs from '../../components/home/BlogsWidget/Latest_blogs';
import Categories5 from '../../components/home/Categories/Categories5';
import { categories } from '../../store/feature/categorySlice';
import SearchBox from '../../components/SearchBox/SearchBox';
import LatestListings from '../../components/home/LatestListings/LatestListings';
import SimpleLatestListings from '../../components/home/LatestListings/SimpleLatestListing';
import CollectionListings from '../../components/home/LatestListings/CollectionsListings';
import FeaturedCard from '../../components/home/FeaturedCrad/FeaturedCard';
import axios from 'axios';
import {
  collectionsSelector,
  getCollections,
} from '../../store/feature/Collections/CollectionsSlice';
import { bannersSelector } from '../../store/feature/Home/bannerSlice';

const CustomHomePageLayout = ({}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(
    //   homeCollections({
    //     authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
    //   })
    // );
    // dispatch(
    //   AllPromoBanners({
    //     authKey: localStorage.getItem(`${TYPE_CONSTANT.DOMAIN}_auth_key`),
    //     bodyParam: { medium: 'web' },
    //   })
    // );

    dispatch(categories({ prams: { parent: 0, type: 'listings' } }));

    dispatch(getCollections({ bodyParam: { scope_type: '4,5' } }));
  }, []);

  const { isFetching, isSuccess, errorMessage } = useSelector(homeSelector);
  const { promo_banners } = useSelector(bannersSelector);

  const { collections } = useSelector(collectionsSelector);

  const { general_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);
  return (
    <>
      <div className=" ">
        <div className="grid md:grid-cols-2 items-center gap-4 md:gap-0">
          <div className="flex flex-col gap-4">
            <h2 className="text-[32px] text-black font-bold pr-4">
              {general_configs?.header_text}
            </h2>
            <p className=" mt-2 text-[16px] text-black font-normal w-5/6">
              {general_configs?.header_description}
            </p>
          </div>
          <div className=" relative   aspect-w-4 aspect-h-2  ">
            {promo_banners?.length > 0 && (
              <img
                alt="image"
                src={promo_banners[0]?.image_path}
                className="  absolute  max-w-[100%]  top-[50%] ltr:left-[50%] rtl:-right-[50%]  -translate-x-1/2 -translate-y-1/2 rounded-image shadow-sm "
              />
            )}
          </div>
        </div>

        {/* Category and search section  */}
        <div className="mt-7 flex  flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
          <div className=" flex-shrink overflow-x-auto">
            <Categories5 />
          </div>
          <div className="flex-none">
            <SearchBox />
          </div>
        </div>

        {/* Collections new featured card section  */}
        <div className="  mt-10 flex flex-col gap-10">
          {collections?.map((collection) => {
            const scope_type = collection.scope_type;

            if (scope_type === 4) {
              return (
                <div key={collection.id}>
                  <CollectionListings products={collection} />
                </div>
              );
            }
            if (scope_type === 5) {
              return (
                <div key={collection.id}>
                  <FeaturedCard collection={collection} />
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default CustomHomePageLayout;
