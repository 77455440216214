/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Image from 'next/image';
import { getThumbnailImage } from '../../../Shared/Constant/Constant';
import Link from 'next/link';
import { Orders_text } from '../../../../constant/Translation/OrdersText';
import ShipmentOrderStatus from '../OrderStatus/OrderStatus2';
import Timeline from './Timeline';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import { useRouter } from 'next/router';
import {
  ShipmentStatus,
  currentShipmentStatus,
} from '../../../Shared/Constant/Status';
import { useAppDispatch, useAppSelector } from '../../../../hooks/Redux';
import {
  getExternalShipmentLabel,
  getSendCloudShipmentLabel,
  store_orderSelector,
} from '../../../../store/feature/store_orderSlice';
import {
  download_icon,
  loading_icon,
} from '../../../Shared/Constant/Icons/AllIcons';
import { base64ToPDFDownload } from '../../../../utils/pdfConverter';

const ShipmentDetails = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const shipments = order_details?.shipments;
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [isLabelFetching, setIsLabelFetching] = useState(false);

  return (
    <div className="flex flex-col gap-6 ">
      {shipments?.map((shipment, index) => {
        if (shipment?.account?.id == router.query.account_id) {
          const OrderDetails = shipment.order_details;

          //  download label
          const download_label = () => {
            setIsLabelFetching(true);
            dispatch(
              getSendCloudShipmentLabel({ shipment_id: shipment?.id })
            ).then((res) => {
              const base64Data = res?.payload?.shipping_label;

              if (base64Data) {
                base64ToPDFDownload({
                  base64Data,
                  fileName: `${order_details?.reference_number}_${shipment?.invoice_number}_label`,
                });
                setIsLabelFetching(false);
              } else {
                setIsLabelFetching(false);
              }
            });
          };

          // external shipment label
          const external_shipment_label = () => {
            setIsLabelFetching(true);
            dispatch(
              getExternalShipmentLabel({
                shipment_id: shipment?.id,
                order_id: order_details?.id,
              })
            ).then((res) => {
              const base64Data = res?.payload?.external_shipment_label;

              if (base64Data) {
                base64ToPDFDownload({
                  base64Data,
                  fileName: `${order_details?.reference_number}_${shipment?.invoice_number}_label`,
                });
                setIsLabelFetching(false);
              } else {
                setIsLabelFetching(false);
              }
            });
          };

          console.log(shipment);

          return (
            <div className=" w-full h-min-[300px] bg-white  shadow-c-sm rounded-card      p-4   sm:p-7   border-opacity-40  flex flex-col gap-4 ">
              {/*  */}
              <div className="">
                <p className="text-primary text-xl leading-6 font-medium   ">
                  {Orders_text.orderdetail.shipment} 1
                </p>
              </div>

              {/* Order Details items  */}
              <div className="flex flex-col gap-2">
                {/* Header */}
                <div className=" grid  grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%] pb-2 border-b-2 border-gray-400     bg-white z-20">
                  <p className=" text-sm sm:text-lg text-black font-semibold ">
                    {Orders_text.orderdetail.items_summary}
                  </p>
                  <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                    {Orders_text.orderdetail.qty}
                  </p>
                  <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                    {Orders_text.orderdetail.price}
                  </p>
                  <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                    {Orders_text.orderdetail.total_price}
                  </p>
                </div>
                {/* Items */}
                <div className="">
                  {OrderDetails?.map((item, index) => {
                    let listing_details = item?.listing;
                    let listing_variant_details = item?.variant
                      ? Object?.keys(item?.variant)?.length > 0
                        ? item?.variant
                        : undefined
                      : undefined;

                    let item_details = listing_variant_details
                      ? listing_variant_details
                      : listing_details;
                    return (
                      <Link
                        key={Math.random()}
                        href={
                          item?.listing?.slug
                            ? `/l/${item?.listing?.slug}`
                            : `/l/${
                                item?.listing?.id
                              }-${item?.listing?.title.replace(
                                /[ /?,#=]+/g,
                                '-'
                              )}?id=true`
                        }
                      >
                        <a
                          className={[
                            ' grid  grid-cols-[50%,20%,30%]   md:grid-cols-[40%,20%,20%,20%] items-center py-3  cursor-pointer',
                            index !== OrderDetails.length - 1 &&
                              'border-b-2 border-gray-400 border-opacity-20',
                          ].join(' ')}
                        >
                          <div
                            className=" flex items-center pl-1"
                            key={item.id}
                          >
                            {item_details?.images?.length > 0 && (
                              <img
                                src={getThumbnailImage(item_details?.images[0])}
                                alt="image"
                                className="w-[50px] h-[50px] object-contain"
                                onError={(e) =>
                                  (e.target.src = item_details?.images[0])
                                }
                              />
                            )}
                            <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                              <p className=" text-sm font-semibold  ">
                                {listing_details.title}{' '}
                                {listing_variant_details &&
                                  listing_variant_details?.title &&
                                  `(${listing_variant_details.title})`}
                              </p>
                              {item?.listing?.fulfilled_by_platform &&
                                TYPE_CONSTANT.SHIPPING_CONFIGS
                                  .fulfillment_by_platform_enabled && (
                                  <img
                                    className=" w-auto h-auto max-w-[80px]"
                                    src={
                                      TYPE_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_platform_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                              {!item?.listing?.fulfilled_by_platform &&
                                TYPE_CONSTANT.SHIPPING_CONFIGS
                                  .fulfillment_by_platform_enabled && (
                                  <img
                                    className="w-auto h-auto  max-w-[80px]"
                                    src={
                                      TYPE_CONSTANT.SHIPPING_CONFIGS
                                        .fulfilled_by_seller_icon_path
                                    }
                                    alt="image"
                                  />
                                )}
                            </div>
                          </div>
                          <div>
                            <p className=" text-sm font-semibold  text-center">
                              X {item.quantity}
                            </p>
                          </div>
                          <div className=" hidden md:block">
                            <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                              {item.list_price.formatted}
                            </p>
                          </div>
                          <div>
                            <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                              {item.list_price.formatted}
                            </p>
                          </div>
                        </a>
                      </Link>
                    );
                  })}
                </div>
              </div>

              <div className="flex items-center justify-between gap-3 flex-wrap mt-2">
                {(shipment?.shipping_method?.channel === 'sendcloud' ||
                  shipment?.external_shipment_id !== '' ||
                  shipment?.external_shipment_type !== '') && (
                  <button
                    className="btn-primary-outline gap-2"
                    onClick={() =>
                      shipment?.shipping_method?.channel === 'sendcloud'
                        ? download_label()
                        : external_shipment_label()
                    }
                  >
                    {Orders_text.orderdetail.shipping_label}{' '}
                    {isLabelFetching ? loading_icon : download_icon}
                  </button>
                )}
                <div className="flex-grow flex items-center justify-end gap-2 ">
                  {/* <p>{Orders_text.orderlist.order_status} :</p> */}
                  <p className="    text-primary font-semibold">
                    {currentShipmentStatus(shipment.status)}
                  </p>

                  {/* Status change btn */}
                  <ShipmentOrderStatus
                    order_details={order_details}
                    shipment={shipment}
                    setShowError={setShowError}
                    setError_message={setError_message}
                    setShowSuccess={setShowSuccess}
                    setSuccess_message={setSuccess_message}
                  />
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ShipmentDetails;
