import { TYPE_CONSTANT } from './constant/Web_constant';

// Product
import ProductHomePageLayout from './themes/product/HomePageLayout';
import ProductHomePageLayout2 from './themes/product/HomePageLayout2';
import ProductMainLayout from './themes/product/MainLayout';
import ProductCategoriesPageLayout from './themes/product/CategoriesPageLayout';
import ListingsPageLayout from './themes/product/ListingsPageLayout';
import SimilarListingsPageLayout from './themes/product/SimilarListingsPageLayout';
import CategoryListingsPageLayout from './themes/product/CategoryListingsPageLayout';
import ProductDetailsPageLayout from './themes/product/ProductDetailsPageLayout';
import ProductAllAccountsPageLayout from './themes/product/AllAccountsPageLayout';
import ProductMyStorePageLayout from './themes/product/MyStorePageLayout';
import ProductCreateStorePageLayout from './themes/product/CreateStorePageLayout';
import ProductEditStorePageLayout from './themes/product/EditStorePageLayout';
import AddListingPageLayout from './themes/product/AddListingPageLayout';
import ProductEditProductPageLayout from './themes/product/EditProductPageLayout';
import ProductEditProfilePageLayout from './themes/product/EditProfilePageLayout';
import ProductSearchPageLayout from './themes/product/SearchPageLayout';
import ProductStoreOrdersPageLayout from './themes/product/StoreOrdersPageLayout';
import ProductStoreOrderDetailsPageLayout from './themes/product/StoreOrderDetailsPageLayout';
import ProductOrdersPageLayout from './themes/product/OrdersPageLayout';
import ProductOrderDetailsPageLayout from './themes/product/OrderDetailsPageLayout';
import ProductAddReviewPageLayout from './themes/product/AddReviewPageLayout';
import CheckoutPageLayout from './themes/common_layouts/CheckoutPageLayout';
import ProductInvitePageLayout from './themes/product/InvitePageLayout';
import ProductPayoutPageLayout from './themes/product/PayoutPageLayout';
import ProductWishListPageLayout from './themes/product/WishListPageLayout';
import CollectionListingsPageLayout from './themes/product/CollectionListingsPageLayout';
import CollectionListingsPageLayout2 from './themes/product/CollectionListingsPageLayout2';

// Product B2C
import B2CProductMainLayout from './themes/product(b2c)/MainLayout';
import B2CProductHomePageLayout from './themes/product(b2c)/HomePageLayout';
import B2CProductCategoriesPageLayout from './themes/product(b2c)/CategoriesPageLayout';
import B2CListingsPageLayout from './themes/product(b2c)/ListingsPageLayout';
import B2CSimilarListingsPageLayout from './themes/product(b2c)/SimilarListingsPageLayout';
import B2CProductDetailsPageLayout from './themes/product(b2c)/ProductDetailsPageLayout';
import B2CProductEditProfilePageLayout from './themes/product(b2c)/EditProfilePageLayout';
import B2CProductSearchPageLayout from './themes/product(b2c)/SearchPageLayout';
import B2CProductOrdersPageLayout from './themes/product(b2c)/OrdersPageLayout';
import B2CProductAddReviewPageLayout from './themes/product(b2c)/AddReviewPageLayout';
import B2CProductInvitePageLayout from './themes/product(b2c)/InvitePageLayout';
import B2CProductWishListPageLayout from './themes/product(b2c)/WishListPageLayout';

// Event
import EventHomePageLayout from './themes/event/HomePageLayout';
import EventMainLayout from './themes/event/MainLayout';
import EventCategoriesPageLayout from './themes/event/CategoriesPageLayout';
import EventListingsPageLayout from './themes/event/EventListingsPageLayout';
import SimilarEventListingsPageLayout from './themes/event/SimilarEventListingsPageLayout';
import EventCategoryListingsPageLayout from './themes/event/EventCategoryListingsPageLayout';
import EventDetailsPageLayout from './themes/event/EventDetailsPageLayout';
import EventAllAccountsPageLayout from './themes/event/AllAccountsPageLayout';
import EventMyStorePageLayout from './themes/event/MyStorePageLayout';
import EventCreateStorePageLayout from './themes/event/CreateStorePageLayout';
import EventEditStorePageLayout from './themes/product/EditStorePageLayout';
import AddEventPageLayout from './themes/event/AddEventPageLayout';
import EventEditProductPageLayout from './themes/event/EditProductPageLayout';
import EventEditProfilePageLayout from './themes/event/EditProfilePageLayout';
import EventSearchPageLayout from './themes/event/EventSearchPageLayout';
import EventStoreOrdersPageLayout from './themes/event/StoreOrdersPageLayout';
import EventOrdersPageLayout from './themes/event/OrdersPageLayout';
import EventOrderDetailsPageLayout from './themes/event/OrderDetailsPageLayout';
import EventAddReviewPageLayout from './themes/event/AddReviewPageLayout';
import EventInvitePageLayout from './themes/event/InvitePageLayout';
import EventPayoutPageLayout from './themes/event/PayoutPageLayout';
import EventWishListPageLayout from './themes/event/WishListPageLayout';
import EventCollectionListingsPageLayout from './themes/event/CollectionListingsPageLayout';
import EventCollectionListingsPageLayout2 from './themes/event/CollectionListingsPageLayout2';

// SAAS
import SaasHomePageLayout from './themes/saas/CustomHomePageLayout';

import SaasMainLayout from './themes/saas/CustomLayout';
import SaasCategoriesPageLayout from './themes/saas/CategoriesPageLayout';
import CustomListingsPageLayout from './themes/saas/CustomListingPageLayout';
import SimilarCustomListingsPageLayout from './themes/saas/SimilarCustomListingsPageLayout';
import SaasCategoryListingsPageLayout from './themes/saas/CategoryListingsPageLayout';
import CustomProductDetailsPageLayout from './themes/saas/CustomProductDetailsPageLayout';
import SaasAllAccountsPageLayout from './themes/saas/AllAccountsPageLayout';
import CustomMyStorePageLayout from './themes/saas/CustomMyStorePageLayout';
import CustomCreateStorePageLayout from './themes/saas/CustomCreateStorePageLayout';
import CustomEditStorePageLayout from './themes/saas/CustomEditStorePageLayout';
import AddCustomListingPageLayout from './themes/saas/AddCUstomListingPageLayout';
import SaasEditProfilePageLayout from './themes/saas/EditProfilePageLayout';
import SaasSearchPageLayout from './themes/saas/SearchPageLayout';
import SaasStoreOrdersPageLayout from './themes/saas/StoreOrdersPageLayout';
import SaasOrdersPageLayout from './themes/saas/OrdersPageLayout';
import SaasOrderDetailsPageLayout from './themes/saas/OrderDetailsPageLayout';
import SaasAddReviewPageLayout from './themes/saas/AddReviewPageLayout';
import SaasCollectionListingsPageLayout from './themes/saas/CollectionListingsPageLayout';
import SaasCollectionListingsPageLayout2 from './themes/saas/CollectionListingsPageLayout2';

// SAAS V2
import SaasHomePageLayoutV2 from './themes/saas(v2)/CustomHomePageLayout';

// Crowdfunding
import CrowdfundingMainLayout from './themes/Crowdfunding/MainLayout';
import CrowdfundingHomePageLayout from './themes/Crowdfunding/HomePageLayout';
import CrowdfundingCategoriesPageLayout from './themes/Crowdfunding/CategoriesPageLayout';
import CrowdfundingListingsPageLayout from './themes/Crowdfunding/ListingsPageLayout';
import CrowdfundingSimilarListingsPageLayout from './themes/Crowdfunding/SimilarListingsPageLayout';
import CrowdfundingCategoryListingsPageLayout from './themes/Crowdfunding/CategoryListingsPageLayout';
import CrowdfundingProductDetailsPageLayout from './themes/Crowdfunding/ProductDetailsPageLayout';
import CrowdfundingAllAccountsPageLayout from './themes/Crowdfunding/AllAccountsPageLayout';
import CrowdfundingCollectionListingsPageLayout from './themes/Crowdfunding/CollectionListingsPageLayout';

// Art & craft
import EtsyMainLayout from './themes/Art & Craft/MainLayout';
import EtsyHomePageLayout from './themes/Art & Craft/HomePageLayout.jsx';
import EtsyListingDetailsPageLayout from './themes/Art & Craft/ListingDetailsPageLayout.jsx';

// Simple
import SimpleMainLayout from './themes/simple/MainLayout';
import SimpleProductDetailsPageLayout from './themes/simple/ProductDetailsPageLayout';
import SimpleHomePageLayout from './themes/simple/HomePageLayout.jsx';
import SimpleOrdersPageLayout from './themes/simple/OrdersPageLayout';
import SimpleOrderDetailsPageLayout from './themes/simple/OrderDetailsPageLayout';
import SimpleAddReviewPageLayout from './themes/simple/AddReviewPageLayout';
import SimpleEditProfilePageLayout from './themes/simple/EditProfilePageLayout';
import SimpleSimilarListingsPageLayout from './themes/simple/SimilarListingsPageLayout';
import PaymentPageLayout from './themes/common_layouts/PaymentPageLayout';

// expert directory theme
import ExpertMainLayout from './themes/expert_directory/MainLayout';
import ExpertHomePageLayout from './themes/expert_directory/HomePageLayout';
import ExpertAccountDetailsPageLayout from './themes/expert_directory/AccountDetailsPageLayout.jsx';

// Integrations Theme
import IntegrationsMainLayout from './themes/Integration/MainLayout';
import IntegrationsHomePageLayout from './themes/Integration/HomePageLayout';

// Common
import CheckoutSuccessPageLayout from './themes/common_layouts/CheckoutSuccessPageLayout';
import BlockedAccountsPageLayout from './themes/common_layouts/BlockedAccountsPageLayout';
import FollowingAccountsPageLayout from './themes/common_layouts/FollowingAccountsPageLayout';
import AddPostPageLayout from './themes/common_layouts/AddPostPageLayout';
import PostPageLayout from './themes/common_layouts/PostPageLayout';
import ArticleDetailsPageLayout from './themes/common_layouts/ArticleDetailsPageLayout';
import SocialFeedPageLayout from './themes/common_layouts/SocialFeedPageLayout';
import CollectionAccountsPageLayout from './themes/common_layouts/CollectionAccountsPageLayout';
import TransactionsPageLayout from './themes/common_layouts/TransactionsPageLayout';
import AccountDetailsPageLayout from './themes/common_layouts/AccountDetailsPageLayout';
import AddressesPageLayout from './themes/common_layouts/AddressesPageLayout';
import FeedbackPageLayout from './themes/common_layouts/FeedbackPageLayout';
import EditPostPageLayout from './themes/common_layouts/EditPostPageLayout';

// Event(b2c)
import B2CEventHomePageLayout from './themes/event(b2c)/HomePageLayout';
import B2CEventMainLayout from './themes/event(b2c)/MainLayout';
import B2CEventCategoriesPageLayout from './themes/event(b2c)/CategoriesPageLayout';
import B2CEventListingsPageLayout from './themes/event(b2c)/EventListingsPageLayout';
import B2CSimilarEventListingsPageLayout from './themes/event(b2c)/SimilarEventListingsPageLayout';
import B2CEventDetailsPageLayout from './themes/event(b2c)/EventDetailsPageLayout';
import B2CEventEditProfilePageLayout from './themes/event(b2c)/EditProfilePageLayout';
import B2CEventSearchPageLayout from './themes/event(b2c)/EventSearchPageLayout';
import B2CEventOrdersPageLayout from './themes/event(b2c)/OrdersPageLayout';
import B2CEventAddReviewPageLayout from './themes/event(b2c)/AddReviewPageLayout';
import B2CEventInvitePageLayout from './themes/event(b2c)/InvitePageLayout';
import B2CEventWishListPageLayout from './themes/event(b2c)/WishListPageLayout';
import BlogsPageLayout from './themes/common_layouts/BlogsPageLayout';
import BlogDetailsPageLayout from './themes/common_layouts/BlogDetailsPageLayout';

// Art & craft (B2C)
import B2CEtsyMainLayout from './themes/Art & Craft(b2c)/MainLayout';
import B2CEtsyHomePageLayout from './themes/Art & Craft(b2c)/HomePageLayout.jsx';
import B2CEtsyListingDetailsPageLayout from './themes/Art & Craft(b2c)/ListingDetailsPageLayout.jsx';

// Crowdfunding(b2c)
import B2CCrowdfundingMainLayout from './themes/Crowdfunding(b2c)/MainLayout';
import B2CCrowdfundingHomePageLayout from './themes/Crowdfunding(b2c)/HomePageLayout';
import B2CCrowdfundingCategoriesPageLayout from './themes/Crowdfunding(b2c)/CategoriesPageLayout';
import B2CCrowdfundingListingsPageLayout from './themes/Crowdfunding(b2c)/ListingsPageLayout';
import B2CCrowdfundingSimilarListingsPageLayout from './themes/Crowdfunding(b2c)/SimilarListingsPageLayout';
import B2CCrowdfundingProductDetailsPageLayout from './themes/Crowdfunding(b2c)/ProductDetailsPageLayout';

// New Theme (Single page Cart)
import SingleCartMainLayout from './themes/singlecart/MainLayout';
import NewHomepageLayout from './themes/singlecart/HomePageLayout';

// Integrations Theme
import SimpleGalleryMainLayout from './themes/simple_gallery/MainLayout';
import SimpleGalleryHomePageLayout from './themes/simple_gallery/HomePageLayout';
import AdminPageLayout from './themes/common_layouts/AdminPagesLayout';
import CityListingsPageLayout from './themes/common_layouts/CityListingsPageLayout';
import ListingCitiesPageLayout from './themes/common_layouts/ListingCitiesPageLayout';

// restaurant theme
import RestaurantMainLayout from './themes/restaurant/MainLayout';
import RestaurantHomePageLayout from './themes/restaurant/HomePageLayout';

// Job portal
import JobPortalMainLayout from './themes/job portal/MainLayout';
import JobPortalHomepageLayout from './themes/job portal/HomepageLayout';
import JobPortalListingDetailsPageLayout from './themes/job portal/ListingDetailsPageLayout';
import AdminPageDetailsLayout from './themes/common_layouts/AdminPageDetailsLayout';

//custom saas directory theme (custom user)
import CustomSaasMainLayout from './themes/custom saas/MainLayout';
import CustomSaasHomePageLayout from './themes/custom saas/HomePageLayout';
import CustomSaasListingDetailsPageLayout from './themes/custom saas/ListingDetailsPageLayout';
import CustomSaasCategoryListingsPageLayout from './themes/custom saas/CategoryListingsPageLayout';
import CustomSaasSearchListingsPageLayout from './themes/custom saas/SearchPageLayout';
import CustomSaasCollectionListingsPageLayout from './themes/custom saas/CollectionListingsPageLayout';
import OrderDetailsPageLayout from './themes/common_layouts/OrderDetailsPageLayout';
import CollectionAccountsPageLayout2 from './themes/common_layouts/CollectionAccountsPageLayout 2';

// Blank template
import BlankMainLayout from './themes/blank/MainLayout';
import BlankProductHomePageLayout from './themes/blank/HomePageLayout';
import BlankProductDetailsPageLayout from './themes/blank/ProductDetailsPageLayout';
import BlankCheckoutPageLayout from './themes/blank/CheckoutPageLayout';
import NotFoundPageLayout from './themes/common_layouts/NotFoundPageLayout';
import ThankyouPage from './themes/common_layouts/ThankyouPage';
import SearchAccountsPageLayout from './themes/common_layouts/SearchAccountsPageLayout';
import WalletPageLayout from './themes/blank/WalletPageLayout';
import KYCPageLayout from './themes/blank/KYCPageLayout';
import CardsPageLayout from './themes/blank/CardsPageLayout';
import BlankBlogDetailsPageLayout from './themes/blank/BlogDetailsPageLayout';
import PageDetailsPageLayout from './themes/blank/PageDetailsPageLayout';
import SubscriptionPageLayout from './themes/blank/SubscriptionPageLayout';
import CollectionsPageLayout from './themes/common_layouts/CollectionsPageLayout';
import AccountCollectionsPageLayout from './themes/common_layouts/AccountCollectionsPageLayout';

module.exports = {
  // home Page
  home_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return (
          <BlankMainLayout>
            <BlankProductHomePageLayout />
          </BlankMainLayout>
        );
        break;
      case 1:
        return (
          <ProductMainLayout>
            <ProductHomePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventHomePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <SaasHomePageLayout />
          </SaasMainLayout>
        );
        break;
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasHomePageLayoutV2 />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <EtsyHomePageLayout />
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingHomePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      case 6:
        return (
          <SimpleMainLayout>
            <SimpleHomePageLayout />
          </SimpleMainLayout>
        );
        break;
      case 7:
        return (
          <ExpertMainLayout>
            <ExpertHomePageLayout />
          </ExpertMainLayout>
        );
        break;
      case 8:
        return (
          <IntegrationsMainLayout>
            <IntegrationsHomePageLayout />
          </IntegrationsMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductHomePageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventHomePageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 12:
        return (
          <B2CEtsyMainLayout>
            <B2CEtsyHomePageLayout />
          </B2CEtsyMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingHomePageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;
      case 14:
        return (
          <SingleCartMainLayout>
            <NewHomepageLayout />
          </SingleCartMainLayout>
        );
        break;
      case 15:
        return (
          <SimpleGalleryMainLayout>
            <SimpleGalleryHomePageLayout />
          </SimpleGalleryMainLayout>
        );
        break;

      case 17:
        return (
          <JobPortalMainLayout>
            <JobPortalHomepageLayout />
          </JobPortalMainLayout>
        );
        break;

      case 18:
        return (
          <RestaurantMainLayout>
            <RestaurantHomePageLayout />
          </RestaurantMainLayout>
        );
        break;
      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasHomePageLayout />
          </CustomSaasMainLayout>
        );
        break;

      default:
        return default_layout(<ProductHomePageLayout />);
    }
  },

  // home Page
  home_page2: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductHomePageLayout2 />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventHomePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <SaasHomePageLayout />
          </SaasMainLayout>
        );
        break;
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasHomePageLayoutV2 />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <EtsyHomePageLayout />
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingHomePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      case 6:
        return (
          <SimpleMainLayout>
            <SimpleHomePageLayout />
          </SimpleMainLayout>
        );
        break;
      case 7:
        return (
          <ExpertMainLayout>
            <ExpertHomePageLayout />
          </ExpertMainLayout>
        );
        break;
      case 8:
        return (
          <IntegrationsMainLayout>
            <IntegrationsHomePageLayout />
          </IntegrationsMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductHomePageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventHomePageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 12:
        return (
          <B2CEtsyMainLayout>
            <B2CEtsyHomePageLayout />
          </B2CEtsyMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingHomePageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;
      case 14:
        return (
          <SingleCartMainLayout>
            <NewHomepageLayout />
          </SingleCartMainLayout>
        );
        break;
      case 15:
        return (
          <SimpleGalleryMainLayout>
            <SimpleGalleryHomePageLayout />
          </SimpleGalleryMainLayout>
        );
        break;

      case 17:
        return (
          <JobPortalMainLayout>
            <JobPortalHomepageLayout />
          </JobPortalMainLayout>
        );
        break;

      case 18:
        return (
          <RestaurantMainLayout>
            <RestaurantHomePageLayout />
          </RestaurantMainLayout>
        );
        break;
      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasHomePageLayout />
          </CustomSaasMainLayout>
        );
        break;

      default:
        return default_layout(<ProductHomePageLayout />);
    }
  },

  //Listing Categories Page:
  all_listing_categories_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductCategoriesPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventCategoriesPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasCategoriesPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductCategoriesPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingCategoriesPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductCategoriesPageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventCategoriesPageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingCategoriesPageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductCategoriesPageLayout />);
    }
  },

  //all_listings_page Page:
  all_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CustomListingsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ListingsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CrowdfundingListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CListingsPageLayout />
          </B2CProductMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventListingsPageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingListingsPageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ListingsPageLayout />);
    }
  },

  // Similar All Listing Page:
  similar_all_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarEventListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimilarCustomListingsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <SimilarListingsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <CrowdfundingSimilarListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout
            pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
            pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <SimpleSimilarListingsPageLayout />
          </SimpleMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CSimilarListingsPageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CSimilarEventListingsPageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingSimilarListingsPageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<SimilarListingsPageLayout />);
    }
  },

  // Collection Listings Page:
  collection_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CollectionListingsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
      case 11:
        return (
          <EventMainLayout>
            <EventCollectionListingsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasCollectionListingsPageLayout />
          </SaasMainLayout>
        );
        break;

      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingCollectionListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasCollectionListingsPageLayout />
          </CustomSaasMainLayout>
        );
        break;

      default:
        return default_layout(<CollectionListingsPageLayout />);
    }
  },

  // Collection Listings Page:
  collection_data_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CollectionListingsPageLayout2 />
          </ProductMainLayout>
        );
        break;
      case 2:
      case 11:
        return (
          <EventMainLayout>
            <EventCollectionListingsPageLayout2 />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasCollectionListingsPageLayout2 />
          </SaasMainLayout>
        );
        break;

      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingCollectionListingsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasCollectionListingsPageLayout />
          </CustomSaasMainLayout>
        );
        break;

      default:
        return default_layout(<CollectionListingsPageLayout2 />);
    }
  },
  // Collection Listings Page:
  collections_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<CollectionsPageLayout />);
    }
  },

  // Account Collections Page:
  account_collections_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<AccountCollectionsPageLayout />);
    }
  },

  // Category Listings Page:
  category_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </ProductMainLayout>
        );
        break;
      case 2:
      case 11:
        return (
          <EventMainLayout>
            <EventCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </SaasMainLayout>
        );
        break;

      case 5:
      case 13:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingCategoryListingsPageLayout
              pageTitle={TYPE_CONSTANT.META_LISTING_CATEGORY_TITLE}
              pageDescription={TYPE_CONSTANT.META_LISTING_CATEGORY_DESCRIPTION}
            />
          </CrowdfundingMainLayout>
        );
        break;

      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasCategoryListingsPageLayout />
          </CustomSaasMainLayout>
        );
        break;
      default:
        return default_layout(<CategoryListingsPageLayout />);
    }
  },

  // city Listings Page:
  listing_cities_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ListingCitiesPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<ListingCitiesPageLayout />);
    }
  },
  // city Listings Page:
  city_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CityListingsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<CityListingsPageLayout />);
    }
  },

  // listing_details_page:
  listing_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return (
          <BlankMainLayout>
            <BlankProductDetailsPageLayout />
          </BlankMainLayout>
        );
        break;
      case 1:
        return (
          <ProductMainLayout>
            <ProductDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <CustomProductDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <EtsyListingDetailsPageLayout />
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CrowdfundingProductDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleProductDetailsPageLayout />
          </SimpleMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductDetailsPageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventDetailsPageLayout />
          </B2CEventMainLayout>
        );
        break;

      case 12:
        return (
          <B2CEtsyMainLayout>
            <B2CEtsyListingDetailsPageLayout />
          </B2CEtsyMainLayout>
        );
        break;
      case 13:
        return (
          <B2CCrowdfundingMainLayout>
            <B2CCrowdfundingProductDetailsPageLayout />
          </B2CCrowdfundingMainLayout>
        );
        break;

      case 17:
        return (
          <JobPortalMainLayout>
            <JobPortalListingDetailsPageLayout />
          </JobPortalMainLayout>
        );
        break;
      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasListingDetailsPageLayout />
          </CustomSaasMainLayout>
        );
        break;

      default:
        return default_layout(<ProductDetailsPageLayout />);
    }
  },

  // all_accounts_page:
  all_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <ProductAllAccountsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <EventAllAccountsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <SaasAllAccountsPageLayout />
          </SaasMainLayout>
        );
        break;

      case 4:
        return (
          <EtsyMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {' '}
              <ProductAllAccountsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <CrowdfundingAllAccountsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(
          <ProductAllAccountsPageLayout />,
          TYPE_CONSTANT.META_ACCOUNT_TITLE,
          TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS
        );
    }
  },
  // all_blocked_accounts_page:
  all_blocked_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
            pageTitle={TYPE_CONSTANT.META_ACCOUNT_TITLE}
            pageDescription={TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS}
          >
            <BlockedAccountsPageLayout />
          </ProductMainLayout>
        );
        break;
      default:
        return default_layout(
          <BlockedAccountsPageLayout />,
          TYPE_CONSTANT.META_ACCOUNT_TITLE,
          TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS
        );
    }
  },
  // all_following_accounts_page:
  all_following_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(
          <FollowingAccountsPageLayout />,
          TYPE_CONSTANT.META_ACCOUNT_TITLE,
          TYPE_CONSTANT.META_ACCOUNT_DESCRIPTIONS
        );
    }
  },

  // Collection accounts Page:
  collection_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<CollectionAccountsPageLayout />);
    }
  },

  // Collection accounts Page:
  collection_data_accounts_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<CollectionAccountsPageLayout2 />);
    }
  },

  // accounts_details_page:
  accounts_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <AccountDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <AccountDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <AccountDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <AccountDetailsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <AccountDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 7:
        return (
          <ExpertMainLayout>
            <ExpertAccountDetailsPageLayout />
          </ExpertMainLayout>
        );
        break;
      default:
        return default_layout(<AccountDetailsPageLayout />);
    }
  },

  // checkout_page:
  checkout_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return (
          <BlankMainLayout>
            <BlankCheckoutPageLayout />
          </BlankMainLayout>
        );
        break;
      case 1:
        return (
          <ProductMainLayout>
            <CheckoutPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<CheckoutPageLayout />);
    }
  },

  // thank_you_subscription_page:
  thank_you_subscription_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ThankyouPage />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<ThankyouPage />);
    }
  },

  // checkout_success_page:
  checkout_success_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <CheckoutSuccessPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <CheckoutSuccessPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <CheckoutSuccessPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <CheckoutSuccessPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <CheckoutSuccessPageLayout />
          </SimpleMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <CheckoutSuccessPageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <CheckoutPageLayout />
          </B2CEventMainLayout>
        );
        break;

      default:
        return default_layout(<CheckoutSuccessPageLayout />);
    }
  },

  // my_store_page
  my_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductMyStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventMyStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <CustomMyStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {' '}
              <ProductMyStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductMyStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductMyStorePageLayout />);
    }
  },

  // create_store_page
  create_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductCreateStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventCreateStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <CustomCreateStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductCreateStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductCreateStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductCreateStorePageLayout />);
    }
  },

  // edit_store_page
  edit_store_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductEditStorePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventEditStorePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <CustomEditStorePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductEditStorePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductEditStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;

      default:
        return default_layout(<ProductEditStorePageLayout />);
    }
  },

  // add_listing_page
  add_listing_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <AddListingPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <AddEventPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <AddCustomListingPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <AddListingPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductEditStorePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<AddListingPageLayout />);
    }
  },

  // edit_listing_page
  edit_listing_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductEditProductPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventEditProductPageLayout />
          </EventMainLayout>
        );
        break;

      default:
        return default_layout(<ProductEditProductPageLayout />);
    }
  },

  // edit_profile_page
  edit_profile_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductEditProfilePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventEditProfilePageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasEditProfilePageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductEditProfilePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductEditProfilePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleEditProfilePageLayout />
          </SimpleMainLayout>
        );
        break;

      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductEditProfilePageLayout />
          </B2CProductMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventEditProfilePageLayout />
          </B2CEventMainLayout>
        );
        break;
      default:
        return default_layout(<ProductEditProfilePageLayout />);
    }
  },
  // edit_profile_page
  wallet_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<WalletPageLayout />);
    }
  },
  // edit_profile_page
  kyc_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<KYCPageLayout />);
    }
  },
  // edit_profile_page
  cards_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<CardsPageLayout />);
    }
  },
  // search_page
  search_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductSearchPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventSearchPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasSearchPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductSearchPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;

      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductSearchPageLayout />
          </B2CProductMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventSearchPageLayout />
          </B2CEventMainLayout>
        );
        break;
      case 20:
        return (
          <CustomSaasMainLayout>
            <CustomSaasSearchListingsPageLayout />
          </CustomSaasMainLayout>
        );
        break;
      default:
        return default_layout(<ProductSearchPageLayout />);
    }
  },
  // search_account_page
  search_account_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <SearchAccountsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<SearchAccountsPageLayout />);
    }
  },

  // account orders
  account_orders: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductStoreOrdersPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventStoreOrdersPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasStoreOrdersPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductStoreOrdersPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductStoreOrdersPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      default:
        return default_layout(<ProductStoreOrdersPageLayout />);
    }
  },

  // account order details
  account_order_details: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductStoreOrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<ProductStoreOrderDetailsPageLayout />);
    }
  },

  // orders
  orders: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductOrdersPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventOrdersPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasOrdersPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductOrdersPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductOrdersPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleOrdersPageLayout />
          </SimpleMainLayout>
        );
        break;

      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductOrdersPageLayout />
          </B2CProductMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventOrdersPageLayout />
          </B2CEventMainLayout>
        );
        break;

      default:
        return default_layout(<ProductOrdersPageLayout />);
    }
  },

  // order details
  buyer_order_details: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <OrderDetailsPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventOrderDetailsPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
        return (
          <SaasMainLayout>
            <SaasOrderDetailsPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductOrderDetailsPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductOrderDetailsPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleOrderDetailsPageLayout />
          </SimpleMainLayout>
        );
        break;
      default:
        return default_layout(<OrderDetailsPageLayout />);
    }
  },

  // Add review
  add_review: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductAddReviewPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventAddReviewPageLayout />
          </EventMainLayout>
        );
        break;
      case 3:
      case 3.2:
        return (
          <SaasMainLayout>
            <SaasAddReviewPageLayout />
          </SaasMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductAddReviewPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductAddReviewPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <SimpleAddReviewPageLayout />
          </SimpleMainLayout>
        );
        break;

      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductAddReviewPageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventAddReviewPageLayout />
          </B2CEventMainLayout>
        );
        break;
      default:
        return default_layout(<ProductAddReviewPageLayout />);
    }
  },

  // Invite page
  Invite_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductInvitePageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventInvitePageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {' '}
              <ProductInvitePageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <ProductInvitePageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <div className="mt-4">
              <ProductInvitePageLayout />
            </div>
          </SimpleMainLayout>
        );

      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductInvitePageLayout />
          </B2CProductMainLayout>
        );
        break;

      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventInvitePageLayout />
          </B2CEventMainLayout>
        );
        break;
      default:
        return default_layout(<ProductInvitePageLayout />);
    }
  },

  // Payment  page
  payment_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <PaymentPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <PaymentPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              {' '}
              <PaymentPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 5:
        return (
          <CrowdfundingMainLayout>
            <PaymentPageLayout />
          </CrowdfundingMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <div className="my-5">
              <PaymentPageLayout />
            </div>
          </SimpleMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <PaymentPageLayout />
          </B2CEventMainLayout>
        );
        break;

      case 10:
        return (
          <B2CProductMainLayout>
            <PaymentPageLayout />
          </B2CProductMainLayout>
        );
        break;

      default:
        return default_layout(<PaymentPageLayout />);
    }
  },

  // Payout  page
  payout_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductPayoutPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventPayoutPageLayout />
          </EventMainLayout>
        );
        break;

      default:
        return default_layout(<ProductPayoutPageLayout />);
    }
  },

  // Wishlist  page
  wishlist_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ProductWishListPageLayout />
          </ProductMainLayout>
        );
        break;
      case 2:
        return (
          <EventMainLayout>
            <EventWishListPageLayout />
          </EventMainLayout>
        );
        break;
      case 4:
        return (
          <EtsyMainLayout>
            <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
              <ProductWishListPageLayout />
            </div>
          </EtsyMainLayout>
        );
        break;
      case 6:
        return (
          <SimpleMainLayout>
            <div className="mt-3 max-w-[1400px] mx-auto px-3 lg:px-9  ">
              <ProductWishListPageLayout />
            </div>
          </SimpleMainLayout>
        );
        break;
      case 10:
        return (
          <B2CProductMainLayout>
            <B2CProductWishListPageLayout />
          </B2CProductMainLayout>
        );
        break;
      case 11:
        return (
          <B2CEventMainLayout>
            <B2CEventWishListPageLayout />
          </B2CEventMainLayout>
        );
        break;
      default:
        return default_layout(<ProductWishListPageLayout />);
    }
  },

  // add_post_page
  add_post_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<AddPostPageLayout />);
    }
  },
  // edit_post_page
  edit_post_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<EditPostPageLayout />);
    }
  },

  //all_contents_page Page:
  all_contents_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
          // pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
          // pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <PostPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(
          <PostPageLayout />,
          TYPE_CONSTANT.META_LISTING_TITLE,
          TYPE_CONSTANT.META_LISTING_DESCRIPTION
        );
    }
  },
  //all_admin_page Page:
  all_admin_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <AdminPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<AdminPageLayout />);
    }
  },
  //all_blogs_page Page:
  all_blogs_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout
          // pageTitle={TYPE_CONSTANT.META_LISTING_TITLE}
          // pageDescription={TYPE_CONSTANT.META_LISTING_DESCRIPTION}
          >
            <BlogsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<BlogsPageLayout />);
    }
  },

  // article_details_page:
  article_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <ArticleDetailsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<ArticleDetailsPageLayout />);
    }
  },
  //
  blog_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return (
          <BlankMainLayout>
            <BlankBlogDetailsPageLayout />
          </BlankMainLayout>
        );
      case 1:
        return (
          <ProductMainLayout>
            <BlogDetailsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<BlogDetailsPageLayout />);
    }
  },
  //
  admin_page_details_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 0:
        return (
          <BlankMainLayout>
            <PageDetailsPageLayout />
          </BlankMainLayout>
        );
        break;
      case 1:
        return (
          <ProductMainLayout>
            <AdminPageDetailsLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<BlogDetailsPageLayout />);
    }
  },
  // all_following_accounts_listings_page:
  social_feed_listings_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <SocialFeedPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<SocialFeedPageLayout />);
    }
  },
  // All Transactions Page:
  transactions_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <TransactionsPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<TransactionsPageLayout />);
    }
  },
  addresses_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <AddressesPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<AddressesPageLayout />);
    }
  },
  feedback_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <FeedbackPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<FeedbackPageLayout />);
    }
  },

  // not found page
  not_found_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      case 1:
        return (
          <ProductMainLayout>
            <NotFoundPageLayout />
          </ProductMainLayout>
        );
        break;

      default:
        return default_layout(<NotFoundPageLayout />);
    }
  },

  //
  subscription_page: () => {
    switch (Number(TYPE_CONSTANT.THEME)) {
      default:
        return default_layout(<SubscriptionPageLayout />);
    }
  },
};

// Default Layout function
const default_layout = (page) => {
  switch (Number(TYPE_CONSTANT.THEME)) {
    case 0:
      return <BlankMainLayout>{page}</BlankMainLayout>;
      break;
    case 1:
      return <ProductMainLayout>{page}</ProductMainLayout>;
      break;
    case 2:
      return <EventMainLayout>{page}</EventMainLayout>;
      break;
    case 3:
    case 3.2:
      return <SaasMainLayout>{page}</SaasMainLayout>;
      break;
    case 4:
      return (
        <EtsyMainLayout>
          <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
            {page}
          </div>
        </EtsyMainLayout>
      );
      break;
    case 5:
      return <CrowdfundingMainLayout>{page}</CrowdfundingMainLayout>;
      break;

    case 6:
      return <SimpleMainLayout>{page}</SimpleMainLayout>;
      break;
    case 7:
      return (
        <ExpertMainLayout>
          <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
        </ExpertMainLayout>
      );
      break;
    case 8:
      return (
        <IntegrationsMainLayout>
          <div className="mt-5    px-5 sm:px-6 md:px-16  ">{page}</div>
        </IntegrationsMainLayout>
      );
      break;
    case 10:
      return <B2CProductMainLayout>{page}</B2CProductMainLayout>;
      break;

    case 11:
      return <B2CEventMainLayout>{page}</B2CEventMainLayout>;
      break;
    case 12:
      return (
        <B2CEtsyMainLayout>
          <div className="max-w-[1400px] mx-auto px-3  md:px-9 mt-3">
            {page}
          </div>
        </B2CEtsyMainLayout>
      );
      break;

    case 13:
      return <B2CCrowdfundingMainLayout>{page}</B2CCrowdfundingMainLayout>;
      break;

    case 14:
      return (
        <SingleCartMainLayout>
          <div className="max-w-7xl mx-auto px-3  md:px-9 mt-3">{page}</div>
        </SingleCartMainLayout>
      );
      break;
    case 15:
      return <SimpleGalleryMainLayout>{page}</SimpleGalleryMainLayout>;
      break;
    case 17:
      return <JobPortalMainLayout>{page}</JobPortalMainLayout>;
      break;

    case 18:
      return (
        <RestaurantMainLayout>
          <div className=" max-w-[1140px] mx-auto px-4  mt-8">{page}</div>
        </RestaurantMainLayout>
      );
      break;
    case 20:
      return <CustomSaasMainLayout>{page}</CustomSaasMainLayout>;
      break;
    default:
      return <ProductMainLayout>{page}</ProductMainLayout>;
  }
};
