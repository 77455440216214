import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collectionsSelector,
  getListingCollections,
} from '../../store/feature/Collections/CollectionsSlice';
import ListingCollections from '../../components/Collections/ListingCollection';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';

const CollectionsPageLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListingCollections({ bodyParam: { scope_type: '4' } }));
  }, []);
  const { listing_collections, isFetching } = useSelector(collectionsSelector);

  return (
    <div className="  mt-10 flex flex-col gap-10">
      {isFetching && <CustomLoading />}
      {listing_collections?.map((collection) => {
        return (
          <div key={collection.id}>
            <ListingCollections product_collection={collection} styles={{}} />
          </div>
        );
      })}
    </div>
  );
};

export default CollectionsPageLayout;
